define("discourse/plugins/retort/discourse/components/retort-button", ["exports", "@glimmer/component", "@ember/object", "@ember/service", "discourse/components/emoji-picker", "discourse/lib/ajax-error", "@ember/component", "@ember/template-factory"], function (_exports, _component, _object, _service, _emojiPicker, _ajaxError, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class RetortButton extends _component.default {
    static hidden(args) {
      return !args.post.can_retort;
    }
    static #_ = (() => dt7948.g(this.prototype, "retort", [_service.service]))();
    #retort = (() => (dt7948.i(this, "retort"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "appEvents", [_service.service]))();
    #appEvents = (() => (dt7948.i(this, "appEvents"), void 0))();
    onSelectEmoji(emoji) {
      this.retort.createRetort(this.args.post, emoji).then(data => {
        this.args.post.set("retorts", data.retorts);
        this.args.post.set("my_retorts", data.my_retorts);
        this.appEvents.trigger("post-stream:refresh", {
          id: data.id
        });
      }).catch(_ajaxError.popupAjaxError);
    }
    static #_3 = (() => dt7948.n(this.prototype, "onSelectEmoji", [_object.action]))();
    static #_4 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <EmojiPicker
          ...attributes
          @icon="far-face-smile"
          @didSelectEmoji={{this.onSelectEmoji}}
          @btnClass="btn-icon btn-flat post-action-menu__retort retort"
        />
      
    */
    {
      "id": "dcl0DVrr",
      "block": "[[[1,\"\\n    \"],[8,[32,0],[[17,1]],[[\"@icon\",\"@didSelectEmoji\",\"@btnClass\"],[\"far-face-smile\",[30,0,[\"onSelectEmoji\"]],\"btn-icon btn-flat post-action-menu__retort retort\"]],null],[1,\"\\n  \"]],[\"&attrs\"],false,[]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/retort/discourse/components/retort-button.js",
      "scope": () => [_emojiPicker.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = RetortButton;
});