define("discourse/plugins/retort/discourse/services/retort", ["exports", "@ember/service", "discourse/lib/ajax", "discourse/lib/ajax-error"], function (_exports, _service, _ajax, _ajaxError) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class Retort extends _service.default {
    static #_ = (() => dt7948.g(this.prototype, "appEvents", [_service.service]))();
    #appEvents = (() => (dt7948.i(this, "appEvents"), void 0))();
    createRetort(_ref, retort) {
      let {
        id
      } = _ref;
      return (0, _ajax.ajax)(`/retorts/${id}.json`, {
        type: "PUT",
        data: {
          retort
        }
      });
    }
    withdrawRetort(_ref2, retort) {
      let {
        id
      } = _ref2;
      return (0, _ajax.ajax)(`/retorts/${id}.json`, {
        type: "DELETE",
        data: {
          retort
        }
      });
    }
    removeRetort(_ref3, retort) {
      let {
        id
      } = _ref3;
      return (0, _ajax.ajax)(`/retorts/${id}/all.json`, {
        type: "DELETE",
        data: {
          retort
        }
      });
    }
    disabledCategories() {
      const categories = this.siteSettings.retort_disabled_categories.split("|");
      return categories.map(cat => parseInt(cat, 10)).filter(Boolean);
    }
    disableShowForTopic(topic) {
      if (!topic) {
        return true;
      }
      const categoryId = topic.get("category.id");
      const disabledCategories = this.disabledCategories();
      return categoryId && disabledCategories.includes(categoryId);
    }
  }
  _exports.default = Retort;
});